<template>
    <div>
        <v-app-bar app fixed>
            <v-row align="center">
                <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                    <v-btn icon @click.stop="drawerConf = !drawerConf"><v-icon>mdi-menu</v-icon></v-btn>
                </v-col>
                <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                    <span class="subtitle-1 mx-auto font-weight-bold text-truncate">{{ $route.meta.title }}</span>
                </v-col>                
            </v-row>
        </v-app-bar>

        <v-navigation-drawer v-model="drawerConf" temporary fixed>
            <template v-slot:prepend>
                <v-list-item class="px-4 pt-1">
                    <v-list-item-title>Menú</v-list-item-title>
                    <v-btn icon @click.stop="drawerConf = !drawerConf">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item>
            </template>
            <v-divider></v-divider>
            <v-list nav dense v-for="(module, index) in modulesOrder" :key="index+'_'+module.title" v-show="can(module.permissions)" v-model="module.active" no-action>
                <v-subheader v-if="module.title">{{ module.title }}</v-subheader>
                <v-list-item v-for="(section, index) in module.sections" :key="index+'_'+section.title" v-show="can(section.permissions)" link @click="goTo(section.redirect)">
                    <v-list-item-icon>
                        <v-icon>{{ section.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ section.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon>{{ (themeDark)?'mdi-brightness-3':'mdi-white-balance-sunny' }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Tema</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-switch color="purple" v-model="get_is_dark" v-on:change="setDarkMode(get_is_dark)"></v-switch>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-list-item link @click="goTo('home')">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Inicio</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon small color="green">mdi-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="green--text text--lighten-1 text-subtitle-2" :style="{ color: styleErrorColor }">
                            {{ getConfigurationName }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="logout">
                    <v-list-item-icon>
                        <v-icon color="error">mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :style="{ color: styleErrorColor }">
                            Cerrar Sesión
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
         </v-navigation-drawer>
    </div>
</template>

<script>
    import default_profile_picture from '../users/images/default_profile_picture.png';

    import { mapState, mapActions, mapGetters } from 'vuex';
    import { can } from '../../src/helpers/permissions';

    export default {
    data: () => ({
        drawer: true,
        group: null,
        drawerConf: false,
        modules: [
            {
                title: 'Administración',
                active: false,
                permissions: [
                    'view-settings', 'view-user-manager', 'view-document-manager', 'view-services'
                ],
                sections: [
                    {
                        title: 'Configuración',
                        icon: 'mdi-tune',
                        redirect: 'settings',
                        permissions: [
                            'view-settings'
                        ],
                    },
                    {
                        title: 'Gestión de Usuarios',
                        icon: 'mdi-account-group',
                        redirect: 'users',
                        permissions: [
                            'view-user-manager'
                        ],
                    },
                    {
                        title: 'Facturación',
                        icon: 'mdi-folder-account',
                        redirect: 'documents',
                        permissions: [
                            'view-document-manager'
                        ],
                    },
                    {
                        title: 'Servicios',
                        icon: 'mdi-certificate',
                        redirect: 'services',
                        permissions: [
                            'view-services'
                        ],
                    },
                ],
            },
            {
                title: 'Recursos Humanos',
                active: false,
                permissions: [
                    'view-rh-management', 'view-rh-employee'
                ],
                sections: [
                    {
                        title: 'Gestión',
                        icon: 'mdi-notebook-multiple',
                        redirect: 'rh/management',
                        permissions: [
                            'view-rh-management'
                        ],
                    },
                    {
                        title: 'Empleados',
                        icon: 'mdi-badge-account-horizontal',
                        redirect: 'rh/employees',
                        permissions: [
                            'view-rh-employee'
                        ],
                    },
                ],
            },
        ],
    }),
    components : {
        
    },
    computed: {
        ...mapState('authentication', ['userAuth']),
        ...mapGetters('configuration', ['getAbilitiesUser', 'getConfigurationName']),
        ...mapState('configuration', ['themeDark']),
        ...mapState('trello', ['projects', 'snapshotStatus']),
        modulesOrder(){
            return this.modules;//_.orderBy(this.modules, 'title', 'asc');
        },
        logged() {
            return (this.$route.name !== "home")
        },
        sideMenu() {
            return this.$vuetify.breakpoint.width <= 1264 && this.logged
        },
        get_is_dark: {
            get: function () {
                return this.themeDark;
            },
            set: function (newValue) {
                return newValue;
            }
        },
        styleErrorColor(){
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.error : this.$vuetify.theme.themes.light.error;
        },
    },
    methods: {
        ...mapActions('configuration', ['setDarkMode']),
        can(permissions){
            return can(this.getAbilitiesUser, permissions);
        },
        logout(){
            this.$router.push({ name: "logout" });
        },
        goTo(name){
            this.$router.push({ name : name }).catch(error => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            });
        },        
        showhere(route){
            return (this.$route.fullPath != route) ? true : false;
        },
        checkImage(image){
            if(image == 'default_user_image.jpg' || image == 'default_profile_picture.png' || image == null){
                return default_profile_picture;                    
            }else{
                return image;
            }
        }        
    },
    watch: {
        get_is_dark: function (val) {
            this.$vuetify.theme.dark = val;
        }
    },
    beforeMount(){
        this.$vuetify.theme.dark = this.get_is_dark;
    }
}
</script>

<style>
    .router-link-exact-active {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 5px;
        color: #1867c0 !important;
        caret-color: #1867c0 !important;
        font-style: italic;
        transition: .3s cubic-bezier(.25,.8,.5,1);
    }
</style>